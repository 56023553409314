
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the theme object.
$feuerwehrheurigen-app-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$azure-palette,
    tertiary: mat.$blue-palette,
  ),
  density: (
    scale: 0,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($feuerwehrheurigen-app-theme);
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($feuerwehrheurigen-app-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($feuerwehrheurigen-app-theme);

html, body {
    height: 100%;
    width: 100%;
}

* {
    padding: 0;
    margin: 0;
}

body {
    margin: 0;
    font-family: "Poppins", "Helvetica Neue", sans-serif;

    --padding-top: env(safe-area-inset-top);
    --padding-bottom: env(safe-area-inset-bottom);
}

body {
    --red: #ea001b;
    --blue: #113448;
    --blue-light : #22aeff;
    --dark: #040e14;
}
